import { Alert, Badge, Box, Button, ColumnLayout, Container, Header, Link, Popover, ProgressBar, SpaceBetween, Spinner, StatusIndicator, Textarea } from '@amzn/awsui-components-react';
import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useParams } from 'react-router-dom';

const queries = require("../../utils/graphql/queries.js");
const subscriptions = require("../../utils/graphql/subscriptions.js");

export default function TrackRequest() {
    const params: any = useParams();
    const [ requestID, setRequestID ] = useState('');
    const [ refreshButtonDisabled, setRefreshDisabled ] = useState(false);
    const [ workflowPolling, setWorkflowPolling ] = useState(false);
    const [ refreshButtonLoading, setRefreshLoading ] = useState(true);
    const [ workflowData, setWorkflowData ] = useState({
        instance_name: 'N/A',
        wf_build_state: 'N/A'
    });
    const [ workflowReqData, setWorkflowReqData ] = useState<any>({});
    
    useEffect(()=> {
        const req = params.requestID;
        setRequestID(req);
        callGrabWorkflow(req);
    }, []);

    const callSubWorkflow = async(req: any) => {
        setRefreshDisabled(true);
        setWorkflowPolling(true);

        const sub = API.graphql({
            query: subscriptions.onUpdateECBakeWorkflowModel,
            variables: {
                request_id: req
            }
        // @ts-ignore
        }).subscribe({
            next: (data: any) => {
                const wf_data = data.value.data.onUpdateECBakeWorkflowModel;

                setWorkflowData(wf_data);

                try {
                    setWorkflowReqData(JSON.parse(wf_data.wf_request_details));
                } catch {}

                if (!wf_data.wf_active) {
                    console.log('Workflow not active, stopping subscription..');
                    setRefreshDisabled(false);
                    setWorkflowPolling(false);
                    sub.unsubscribe();
                }
            },
            error: (error: any) => {
                console.log('Error trying to subscribe to data..', error);
                setRefreshDisabled(false);
                setWorkflowPolling(false);
            }
        });
    }

    const callGrabWorkflow = async(req: any) => {
        setRefreshLoading(true);

        try {
            const resp: any = await API.graphql({
                query: queries.getECBakeWorkflowModel,
                variables: {
                    request_id: req
                }
            });

            const data = resp.data.getECBakeWorkflowModel;


            if (data) {
                setWorkflowData(data);

                try {
                    setWorkflowReqData(JSON.parse(data.wf_request_details));
                } catch {}
                
                if (data.wf_active) {
                    callSubWorkflow(req)
                }
            }
            
            setRefreshLoading(false);

        } catch (e) {
            console.log('Could not grab workflow data for id.', e);
            setRefreshLoading(false);
        }
    }

    const statusBadge = (build_state: string) => {
        switch(build_state){
            case "WAITING_TO_START":
                return <Badge color='grey'>Not started</Badge>
            case "BUILDING":
                return <Badge color='blue'>In progress</Badge>
            case "FAILED":
                    return <Badge color='red'>Failed</Badge>
            case "COMPLETE":
                return <Badge color='green'>Complete</Badge>
            case "COMPLETE_WITH_WARNINGS":
                return <Badge color='green'>Complete</Badge>
            default:
                return <Badge color='grey'>Unknown build state</Badge>
        }
    }

    return (
        <div>
            <SpaceBetween size='m'>
                {workflowData.wf_build_state === 'FAILED' && workflowReqData.hostclass_ldap_add && workflowReqData.hostclass_ldap_add.includes('success') ? (
                    <Alert
                        visible={true}
                        type='error'
                        header="Attention required"
                    >
                        This workflow failed to complete after adding an instance to your hostclass. To avoid issues, 
                        you must remove the failed computer name ({workflowData.instance_name}.ant.amazon.com) from your hostclass, 
                        preferably by using <Link external href='https://selfie.corp.amazon.com/workflows/HostclassSlayer'>HostclassSlayer</Link>. 
                    </Alert>
                ): null}
                <Header 
                    variant='h1' 
                    actions={
                        <SpaceBetween direction='horizontal' size='xs'>
                            <Button iconAlign="left" iconName="refresh" loading={refreshButtonLoading} disabled={refreshButtonDisabled} onClick={() => callGrabWorkflow(requestID)} />
                        </SpaceBetween>
                    }
                >
                    {requestID.length < 1 ? 'No request found' : `Request ${requestID}`}
                    <SpaceBetween size='xxs' direction='horizontal'>
                        <Badge color='grey'>{workflowData.instance_name || 'Instance name unassigned'}</Badge>
                        {statusBadge(workflowData.wf_build_state)}
                        {workflowPolling ? 
                            <Badge color='blue'> Polling for updates <Spinner size="normal" /> </Badge> :
                            null
                        }
                    </SpaceBetween>
                </Header>
                <StepDetails 
                    workflowData={workflowData}
                />
                <RequestOverview 
                    workflowData={workflowData}
                    workflowReqData={workflowReqData}
                />
                <ErrorBoundary
                    FallbackComponent={ErrorFallbackComponent}
                >
                    <WorkflowResult
                        workflowData={workflowData}
                        workflowReqData={workflowReqData}
                    />
                </ErrorBoundary>
            </SpaceBetween>
        </div>
    );
}

function RequestOverview(props: {
    workflowData: any
    workflowReqData: any
}) {
    useEffect(() => {
        setWFProgress(props.workflowData.wf_build_step);
    }, [props.workflowData]);

    const [ workflowProgress, setWorkflowProgress ] = useState(0);

    const workflowBuildState = (state: any) => {
        switch(state) {
            case "WAITING_TO_START":
                return {"state": "Waiting in queue", "status_icon": "in-progress"}
            case "BUILDING":
                return {"state": "In progress", "status_icon": "in-progress"}
            case "FAILED":
                    return {"state": "Failed", "status_icon": "error"}
            case "COMPLETE":
                return {"state": "Ready", "status_icon": "success"}
            case "COMPLETE_WITH_WARNINGS":
                return {"state": "Ready, with notices", "status_icon": "success"}
            default:
                return {"state": "Unknown state", "status_icon": "error"}
        }
    }

    const setWFProgress = (wf_step: string) => {
        const step_order = [
            'VERIFYING_REQUEST',
            'REQUESTING_INSTANCE',
            'WAITING_FOR_INSTANCE',
            'VERIFYING_INSTANCE_HEALTH',
            'WAITING_FOR_WINDOWS',
            'VERIFYING_HOSTNAME_WINDOWS',
            'VERIFYING_LDAP',
            'ADDING_TO_AD',
            'REBOOTING_FOR_AD_JOIN',
            'ADDING_TO_HOSTCLASS_LDAP',
            'UPDATING_NETWORK_SETTINGS',
            'UPDATING_ADMIN_GROUP',
            'INSTALLING_APPLICATIONS',
            'REBOOTING_FOR_APP_INSTALL',
            'VERIFYING_INSTALLED_APPS',
            'INSTANCE_READY'
        ];

        const wf_step_idx = step_order.indexOf(wf_step);
        let wf_progress = 0;

        if (wf_step_idx >-1) {
            const per_step = (100 / step_order.length);
            wf_progress = ((wf_step_idx +1) * per_step);
        }

        setWorkflowProgress(wf_progress);
    }

    return (
        <div>
            <Container header={
                <Header variant='h2'>
                    Request Details
                </Header>}
            >
                <ColumnLayout columns={4} variant="text-grid">
                    <SpaceBetween size="l">
                        <ProgressBar
                            value={workflowProgress}
                            label="Build Status"
                            description={props.workflowData.wf_build_step}
                            additionalInfo={props.workflowData.wf_step_details != null ? props.workflowData.wf_step_details : ''}
                            variant="key-value"
                            resultText='Workflow complete' // when completed
                            // @ts-ignore
                            status={workflowBuildState(props.workflowData.wf_build_state).status_icon}
                        />
                        <div>
                            <Box variant="awsui-key-label">Requester</Box>
                            <div>{`${props.workflowData.requested_by_alias}@`}</div>
                        </div>
                        <div>
                            <Box variant="awsui-key-label">Request Time (UTC)</Box>
                            <div>{(new Date(props.workflowData.wf_request_received_ts_utc)).toUTCString()}</div>
                        </div>
                    </SpaceBetween>

                    <SpaceBetween size='l'>
                        <div>
                            <Box variant="awsui-key-label">Instance Region</Box>
                            <div>{props.workflowData.instance_region}</div>
                        </div>
                        <div>
                            <Box variant="awsui-key-label">Instance AZ</Box>
                            <div>{props.workflowData.instance_az}</div>
                        </div>
                        <div>
                            <Box variant="awsui-key-label">Stage</Box>
                            <div>{props.workflowData.gso_stage}</div>
                        </div>
                    </SpaceBetween>

                    <SpaceBetween size='l'>
                        <div>
                            <Box variant="awsui-key-label">Instance Type</Box>
                            <div>{props.workflowData.instance_type}</div>
                        </div>
                        <div>
                            <Box variant="awsui-key-label">Operating System</Box>
                            <div>
                                {props.workflowData.instance_os ? 
                                `EC-Bake ${props.workflowData.instance_os.toUpperCase()}`:'-'}
                            </div>
                            <div>
                                {
                                    props.workflowReqData ? `(${props.workflowReqData.instance_ami ? props.workflowReqData.instance_ami : 'No AMI'})` :
                                    null
                                }
                                {/* {`(${props.workflowReqData.instance_ami ? props.workflowReqData.instance_ami : 'No AMI'})`} */}
                            </div>
                        </div>
                        <div>
                            <Box variant="awsui-key-label">Instance name</Box>
                            <div>{props.workflowData.instance_name}</div>
                        </div>
                    </SpaceBetween>

                    <SpaceBetween size='l'>
                        <div>
                            <Box variant="awsui-key-label">Host Type</Box>
                            <div>{props.workflowData.gso_host_type}</div>
                        </div>
                        <div>
                            <Box variant="awsui-key-label">Hostclass</Box>
                            <div>{props.workflowData.hostclass}</div>
                        </div>
                        <div>
                            <Box variant="awsui-key-label">Description</Box>
                            <div>{props.workflowData.instance_description}</div>
                        </div>
                    </SpaceBetween>
                </ColumnLayout>
            </Container>
        </div>
    );
}

function StepDetails(props: {
    workflowData: any
}) {
    const getStepType = (status: string) => {
        switch(status) {
            case "PENDING":
                return {'type': 'pending', 'msg': 'Not started'}
            case "IN_PROGRESS":
                return {'type': 'loading', 'msg': 'In progress'}
            case "COMPLETE":
                return {'type': 'success', 'msg': 'Done'}
            case "FAILED":
                return {'type': 'error', 'msg': 'Failed'}
            case "COMPLETE_WITH_WARNING":
                return {'type': 'warning', 'msg': 'Done, but needs attention'}
            default:
                return {'type': 'in-progress', 'msg': 'Waiting on status'}
        }
    }

    return (
        <Container header={<Header variant="h2">Baking Steps</Header>}>
            <ColumnLayout columns={4} variant="text-grid">
                <div>
                    <Box variant="awsui-key-label">Instance Requested</Box>
                    
                    {/* @ts-ignore */}
                    <StatusIndicator type={getStepType(props.workflowData.instance_request_status).type}>
                        {getStepType(props.workflowData.instance_request_status).msg}
                    </StatusIndicator>
                </div>
                <div>
                    <Box variant="awsui-key-label">Instance Launched</Box>
                    {/* @ts-ignore */}
                    <StatusIndicator type={getStepType(props.workflowData.instance_launch_status).type}>
                        {getStepType(props.workflowData.instance_launch_status).msg}
                    </StatusIndicator>
                </div>
                <div>
                    <Box variant="awsui-key-label">Instance Configured</Box>
                    {/* @ts-ignore */}
                    <StatusIndicator type={getStepType(props.workflowData.instance_configure_status).type}>
                        {getStepType(props.workflowData.instance_configure_status).msg}
                    </StatusIndicator>
                </div>
                <div>
                    <Box variant="awsui-key-label">Instance Ready</Box>
                    {/* @ts-ignore */}
                    <StatusIndicator type={getStepType(props.workflowData.instance_ready_status).type}>
                        {getStepType(props.workflowData.instance_ready_status).msg}
                    </StatusIndicator>
                </div>
            </ColumnLayout>
        </Container>
    );
}



function WorkflowResult(props: {
    workflowData: any
    workflowReqData: any
}) {
    const ValueWithLabel = ({ label, children }: any) => (
        <div>
            <Box variant="awsui-key-label">{label}</Box>
            <div>{children}</div>
        </div>
    );

    const ApplicationWarning = ({service}: any) => (
        <Popover
            dismissAriaLabel="Close"
            header="Unverified"
            size="medium"
            triggerType="text"
            content={
                <React.Fragment key={service}>
                    EC-BakeOven could not verify this service was
                    running. Please log into the host and verify
                    this was installed successfully.
                </React.Fragment>
            }
      > <StatusIndicator type='warning'>{service}</StatusIndicator> </Popover>
    );

    const getAppName = (service: string) => {
        switch(service) {
            case "csagent": return "Crowdstrike";
            case "CcmExec": return "SCCM";
            case "QualysAgent": return service;
            default: return service;
        }
    }

    
    return (
        <Container header={<Header variant='h2'>Workflow Output</Header>}>
            <ColumnLayout columns={2} variant='text-grid'>
                <div>
                    <Box variant="h3" padding={{ bottom: "xs" }}>
                        Health Checks
                    </Box>
                    <ColumnLayout columns={2} variant='text-grid'>
                        <SpaceBetween size="l">
                            <ValueWithLabel label="Administrator groups added">
                                    {props.workflowReqData && props.workflowReqData.admin_group_result ? 
                                        <div>
                                            {JSON.parse(props.workflowReqData.admin_group_result).added.map((item: string) => (
                                                <div key={item}>
                                                    <StatusIndicator>{item}</StatusIndicator>
                                                </div>
                                            ))}
                                            {JSON.parse(props.workflowReqData.admin_group_result).failed.map((item: string) => (
                                                <div key={item}>
                                                    <StatusIndicator type='error'>{item}</StatusIndicator>
                                                </div>
                                            ))}
                                        </div> : <div> - </div>
                                    }
                            </ValueWithLabel>
                        </SpaceBetween>
                        <SpaceBetween size="l">
                            <ValueWithLabel label="Application installations verified">
                                    {props.workflowReqData && props.workflowReqData.app_install_result ? 
                                        <div>
                                            {JSON.parse(props.workflowReqData.app_install_result).success.map((item: string) => (
                                                <div key={item}>
                                                    <StatusIndicator>{getAppName(item)}</StatusIndicator>
                                                </div>
                                            ))}
                                            {JSON.parse(props.workflowReqData.app_install_result).failed.map((item: string) => (
                                                <div key={item}>
                                                    <ApplicationWarning service={getAppName(item)} />
                                                </div>
                                            ))}
                                        </div> : <div> - </div>
                                    }
                            </ValueWithLabel>
                        </SpaceBetween>
                    </ColumnLayout>
                    {props.workflowReqData && props.workflowData.wf_build_state == 'COMPLETE' ? 
                        <div>
                            <Box variant="h3" padding={{ top: "l", bottom: "xs" }}>
                                Instance Details
                            </Box>
                            <ColumnLayout columns={2} variant='text-grid'>
                                <SpaceBetween size="xs">
                                    <div>
                                        <Box variant="awsui-key-label">Instance ID</Box>
                                        {props.workflowReqData.new_instance_id}
                                    </div>
                                    <div>
                                        <Box variant="awsui-key-label">Instance IPv4 Address</Box>
                                        {props.workflowReqData.new_instance_ip ? props.workflowReqData.new_instance_ip : '-'}
                                    </div>
                                </SpaceBetween>
                                <SpaceBetween size="xs">
                                    <div>
                                        <Box variant="awsui-key-label">VPC ID</Box>
                                        {props.workflowReqData.instance_vpc_id}
                                    </div>
                                    <div>
                                        <Box variant="awsui-key-label">Security Group</Box>
                                        {props.workflowReqData.instance_sg_name}
                                    </div>
                                </SpaceBetween>
                            </ColumnLayout>
                        </div> : <div/>
                    }
                </div>
                <div>
                    <Box variant="h3" padding={{ bottom: "xxs" }}>
                        Failure Details
                    </Box>
                    <ValueWithLabel label="">
                        <Textarea
                            value={props.workflowData.wf_fail_details}
                            rows={9}
                            disableBrowserAutocorrect
                            disableBrowserSpellcheck
                            readOnly
                            placeholder='Output from failures will appear here if the workflow fails to complete.'
                        />
                    </ValueWithLabel>
                </div>
            </ColumnLayout>
        </Container>
    )
}

function ErrorFallbackComponent({error, resetErrorBoundary}: any) {
    return (
      <div role="alert">
        <p>There was an issue loading this component:</p>
        <pre>{error.message}</pre>
      </div>
    )
  }